import React from 'react'
import clsx from 'clsx'

interface SectionProps {
  title: string
  subtitle?: string
  children: React.ReactNode
  className?: string
}

function Section({ title, subtitle, children, className }: SectionProps) {
  return (
    <section className={clsx('flex flex-col', className)}>
      <header className="mb-4 ml-4 lg:mb-2">
        <h4 className="text-xl font-semibold">{title}</h4>
        {subtitle && <p>{subtitle}</p>}
      </header>
      {children}
    </section>
  )
}

export default Section
