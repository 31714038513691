import React, { useEffect, useRef } from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import { analytics } from '~/analytics'
import { useIntersectionObserver } from '~/hooks'
import { cloudinary } from '~/utils'
import { analyticsCreativeSlot, flagQueries, transformations } from '~/utils/constants'
import { dimensions } from '~/variables'

interface ImageProps {
  mobileImage: string
  desktopImage: string
  $isFullWidth: boolean
}

const Image = styled.div<ImageProps>`
  background-image: url(${props => props.mobileImage});
  background-size: 100% 100%;
  height: calc(100vw / 2);
  width: 100%;

  ${up('lg')} {
    background-image: url(${props => props.desktopImage});
    ${props =>
      props.$isFullWidth ? 'height: calc(100vw / 4)' : `height: calc((100vw - ${dimensions.cartWidth}px) / 4)`}
  }
`

const Anchor = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  display: block;
  width: 100%;
`

const getOptimizedImages = props => {
  const { desktopImage, mobileImage } = props

  const optimizedImages = {
    desktopImage: cloudinary.applyTransformations(desktopImage, transformations.DESKTOP_BANNER),
    mobileImage: cloudinary.applyTransformations(mobileImage, transformations.MOBILE_BANNER),
  }

  if (!mobileImage) {
    optimizedImages.mobileImage = optimizedImages.desktopImage
  }

  return optimizedImages
}

const Banner = props => {
  const { link, style, className, onRender, isEventSent } = props

  const optimizedImages = getOptimizedImages(props)

  const isFullWidth = useFlag(flagQueries.HAS_FULL_WIDTH)

  const onClick = () => analytics.selectPromotion(analyticsCreativeSlot.MAIN_BANNERS)

  const ref = useRef(null)

  const isObserved = useIntersectionObserver(ref, 0.95)

  useEffect(() => {
    if (!isEventSent && isObserved) {
      onRender()
    }
  })

  if (!link) {
    return (
      <Image
        $isFullWidth={isFullWidth}
        style={style}
        className={className}
        onClick={onClick}
        {...optimizedImages}
        ref={ref}
      />
    )
  }

  return (
    <Anchor href={link} style={style} className={className}>
      <Image $isFullWidth={isFullWidth} onClick={onClick} {...optimizedImages} ref={ref} />
    </Anchor>
  )
}

export default Banner
