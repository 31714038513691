import * as React from 'react'

import { Meta } from '~/components'
import HomeScreen from '~/screens/home'
import { getMetaInfo } from '~/utils'
import type { GetMetaInfoResult } from '~/utils/get-meta-info'

interface HomePageProps {
  metaInfo: GetMetaInfoResult
}

function HomePage(props: HomePageProps) {
  const { metaInfo } = props

  return (
    <>
      <Meta {...metaInfo} />
      <HomeScreen />
    </>
  )
}

HomePage.getInitialProps = (context: PageContext): HomePageProps => {
  return {
    metaInfo: getMetaInfo(context),
  }
}

export default HomePage
