import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import clsx from 'clsx'
import { map } from 'lodash'
import { useFlag } from 'toggled'

import { categoriesQuery } from '~/gql/queries'
import { useVendor } from '~/hooks'
import { flagQueries } from '~/utils/constants'

import Section from '../section'

import Category from './category'

function Categories() {
  const { data } = useQuery(categoriesQuery)

  const dataSource = data?.vendor?.categories

  const isFullWidth = useFlag(flagQueries.HAS_FULL_WIDTH)

  const vendor = useVendor()

  let categoriesOrBrandsTitle = 'Categorías'

  let categoriesOrBrandsSubtitle: string | undefined

  if (vendor.settings.categoryAsBrand) {
    categoriesOrBrandsTitle = 'Marcas'

    categoriesOrBrandsSubtitle = '¡Dale click a las marcas para ver más productos!'
  }

  return (
    <Section title={categoriesOrBrandsTitle} subtitle={categoriesOrBrandsSubtitle}>
      <div
        className={clsx(
          'grid grid-cols-1 gap-0.5 md:grid-cols-2 md:gap-4 md:px-4',
          isFullWidth ? 'lg:grid-cols-4' : 'lg:grid-cols-3',
        )}
      >
        {map(dataSource, category => (
          <Category key={category.id} {...category} />
        ))}
      </div>
    </Section>
  )
}

export default Categories
