import * as React from 'react'
import { isEmpty } from 'lodash'

import { MostPurchasedPreview, useMostPurchasedProductsQuery } from '~/screens/repurchase/shortcut'

import Section from '../section'

function RepurchaseSection() {
  const { catalogQuery, mostPurchasedQuery } = useMostPurchasedProductsQuery()

  if (isEmpty(catalogQuery.data?.data)) {
    return null
  }

  return (
    <Section title="Comprar de nuevo">
      <MostPurchasedPreview mostPurchasedQuery={mostPurchasedQuery} catalogQuery={catalogQuery} />
    </Section>
  )
}

export default RepurchaseSection
