import React from 'react'

import { Media } from '~/components'
import useVendor from '~/hooks/use-vendor'
import Image from '~/truck/image'
import { cloudinary } from '~/utils'

const photos = {
  desktop: cloudinary.getFullUrl('v1533086222/saas/tortas-gaby/banners/quote-desktop.jpg'),
  mobile: cloudinary.getFullUrl('v1533084354/saas/tortas-gaby/banners/quote-mobile.jpg'),
}

const optimizedPhotos = {
  desktop: cloudinary.applyTransformations(photos.desktop),
  mobile: cloudinary.applyTransformations(photos.mobile),
}

const Component = props => {
  const { photo, style, className } = props
  const vendor = useVendor()

  return (
    <a href={vendor.whatsappUrl} style={style} className={className} rel="noopener noreferrer nofollow" target="_blank">
      <Image src={photo} style={{ width: '100%' }} />
    </a>
  )
}

const TgQuote = props => {
  const { desktop, mobile } = optimizedPhotos

  return <Media desktop={<Component {...props} photo={desktop} />} mobile={<Component {...props} photo={mobile} />} />
}

export default TgQuote
