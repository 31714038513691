import * as React from 'react'
import clsx from 'clsx'
import Link from 'next/link'

import useVendor from '~/hooks/use-vendor'
import { cloudinary } from '~/utils'
import { transformations } from '~/utils/constants'

interface CategoryProps {
  name: string
  image?: string
  slug: string
  encodedId: string
}

function Category({ name, image, slug, encodedId }: CategoryProps) {
  const vendor = useVendor()

  const { hideCategoryName } = vendor.settings

  const optimizedImage = cloudinary.applyTransformations(image, transformations.CATEGORY)

  return (
    <div
      className={clsx('h-36', image && 'bg-center bg-no-repeat', image && hideCategoryName ? 'bg-contain' : 'bg-cover')}
      style={{ backgroundImage: image ? `url(${optimizedImage})` : undefined }}
    >
      <Link href={`/c/${slug}-${encodedId}`} passHref>
        <a
          className={clsx(
            'flex h-full items-center justify-center p-4 text-xl font-semibold text-white lg:transition-colors lg:hover:bg-black/20',
            !hideCategoryName && 'bg-zinc-800/30',
          )}
        >
          <span className={clsx(hideCategoryName && 'invisible')}>{name}</span>
        </a>
      </Link>
    </div>
  )
}

export default Category
