import React from 'react'
import { Flag, useFlag } from 'toggled'

import { useUser } from '~/hooks'
import { MainLayout } from '~/layouts'
import theme from '~/truck/theme'
import { flags } from '~/utils/constants'

import Banners from './banners'
import CategoriesSection from './categories-section'
import FeaturedProductsSection from './featured-products-section'
import RepurchaseSection from './repurchase-section'
import TgQuote from './tg-quote'

function HomeScreen() {
  const user = useUser()

  const allowRepurchaseFF = useFlag(flags.ALLOW_REPURCHASE)

  const allowRepurchase = allowRepurchaseFF && user.isLoggedIn

  return (
    <MainLayout>
      <Banners />
      <div className="mt-4 flex w-full flex-col gap-4">
        {allowRepurchase && <RepurchaseSection />}
        <FeaturedProductsSection />
        <CategoriesSection />
      </div>
      <Flag flagQuery={flags.TG_QUOTE}>
        <TgQuote style={{ marginTop: theme.spacing.comfortable }} />
      </Flag>
    </MainLayout>
  )
}

export default HomeScreen
