import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'

import { analytics } from '~/analytics'
import { Carousel } from '~/components'
import { bannersQuery } from '~/gql/queries'
import { analyticsCreativeSlot } from '~/utils/constants'

import Banner from '../banner'

const Banners = () => {
  const { data } = useQuery(bannersQuery)

  const dataSource = data?.vendor?.banners

  const [isEventSent, setIsEventSent] = useState(false)

  const onBannerRender = () => {
    analytics.viewPromotion(analyticsCreativeSlot.MAIN_BANNERS)

    setIsEventSent(true)
  }

  useEffect(() => {
    onBannerRender()
  }, [])

  return (
    <Carousel
      dataSource={dataSource}
      afterSlide={() => setIsEventSent(false)}
      renderer={banner => {
        return <Banner key={banner.id} onRender={onBannerRender} isEventSent={isEventSent} {...banner} />
      }}
    />
  )
}

export default Banners
