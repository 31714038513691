import * as React from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { isEmpty, map, take } from 'lodash'
import Link from 'next/link'

import type { HTTPError } from '~/errors'
import { useIsMobile } from '~/hooks'
import Icon from '~/truck/icon'
import NewButton from '~/truck/new-button/NewButton'
import theme from '~/truck/theme'
import type { ICatalogQueryData } from '~/types/catalog'
import type { IMostPurchasedProductsData } from '~/types/users'

import { getCatalogParams, getSortedPairs } from '../contents/most-purchased'
import { RepurchaseLineItemImage } from '../line-item'

export interface MostPurchasedPreviewProps {
  catalogQuery: UseQueryResult<ICatalogQueryData>
  mostPurchasedQuery: UseQueryResult<IMostPurchasedProductsData>
}

export function useMostPurchasedProductsQuery() {
  const mostPurchasedQuery = useQuery<IMostPurchasedProductsData, HTTPError>('/account/purchased-products')

  const catalogParams = getCatalogParams(mostPurchasedQuery)

  const catalogQuery = useQuery<ICatalogQueryData, HTTPError>(['/catalog/products', catalogParams], {
    enabled: !!catalogParams,
  })

  return { mostPurchasedQuery, catalogQuery }
}

export function MostPurchasedPreview(props: MostPurchasedPreviewProps) {
  const { catalogQuery, mostPurchasedQuery } = props

  const limit = useIsMobile() ? 3 : 4

  const sortedCatalogItems = React.useMemo(() => {
    return take(map(getSortedPairs(catalogQuery, mostPurchasedQuery), 'product'), limit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogQuery.dataUpdatedAt, mostPurchasedQuery.dataUpdatedAt, limit])

  if (isEmpty(sortedCatalogItems)) {
    return null
  }

  return (
    <div className="flex items-center gap-4 bg-white p-2 md:ml-4 md:w-fit">
      {map(sortedCatalogItems, catalogProduct => {
        return (
          <RepurchaseLineItemImage
            key={catalogProduct.id}
            src={catalogProduct.photo}
            height={theme.spacing.cozy * 6}
            width={theme.spacing.cozy * 6}
          />
        )
      })}
      <Link href="/mi-cuenta/mas-comprados">
        <NewButton variant="secondary" size="icon">
          <Icon color="currentColor" type="caret-right" />
        </NewButton>
      </Link>
    </div>
  )
}
